/* style.css */

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.product-thumbnails img {
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
    height: 80px;
    object-fit: cover;
    border: 2px solid transparent;
}

.product-thumbnails img:hover,
.product-thumbnails img.active {
    border: 2px solid #007bff;
}

.main-image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.color-options > div {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #ddd;
}
