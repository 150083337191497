.ui.menu {
    border-radius: 0%;
}

@media (max-width: 768px) { /* Adjust max-width as needed for your mobile breakpoint */
    .sidebar.visible {
        display: none !important; /* Hide sidebar by default on mobile */
    }
    .navbar-toggle {
        display: block !important; /* Display hamburger menu icon */
    }
}
