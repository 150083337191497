.custom-nav-item {
    margin-right: 20px; /* Adjust the gap between items */
    padding: 10px 15px;
    transition: background-color 0.3s ease, font-weight 0.3s ease;
  }
  
  .custom-nav-item:hover {
    background-color: #ffffff; /* Change background color on hover */
    color: #ffa62b; /* Change text color on hover */
    font-weight: bold; /* Make text bold on hover */
    border-radius: 5px; /* Make it look like a button */
    text-decoration: none; /* Remove underline on hover */
  }
  
  .dropdown-menu li {
    font-weight: bold;
  }