.image-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    border: 2px dashed #ccc;
    cursor: pointer;
    background-color: #f8f9fa;
}

.image-placeholder:hover {
    background-color: #e9ecef;
}

.carousel-placeholder {
    height: 100%;
}

.image-uploader {
    position: relative;
    width: 100%;
}

.carousel-item-container {
    position: relative;
}

.clear-icon {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.865);
    color: white;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 10px;
    display: none;
}

.carousel-item-container:hover .clear-icon {
    display: block;
}

.carousel-item-container img,
.carousel-item-container video {
    width: 100%;
    height: auto;
}
