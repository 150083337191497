.main-banner {
  background-color: #f8f9fa;
  padding: 2rem 0;
}

.banner-content {
  padding: 2rem;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
}

.highlight {
  color: #ffc107; /* Bootstrap's warning color */
}

.description {
  font-size: 1rem;
  margin: 1rem 0;
}

.see-more-button {
  background-color: #ffc107;
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.see-more-button:hover {
  background-color: #e0a800; /* Slightly darker shade for hover */
}

.brands {
  font-size: 1rem;
  margin-top: 1rem;
}

.brands ul {
  list-style-type: none;
  padding: 0;
}

.brands li {
  margin-bottom: 0.5rem;
}

.carousel-control-prev-icon {
  color: black;
}

.carousel-control-next-icon {
  color: black;
}